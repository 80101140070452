@import url(https://fonts.googleapis.com/css?family=Oswald:700);
/*.modal-open{overflow:hidden}*/

/*.modal{position:fixed;top:50%;right:0;bottom:0;left:50%;z-index:1050;display:none;overflow:hidden;outline:0;transform: translate(-50%, -50%);}*/

/*.modal-open .modal{overflow-x:hidden;overflow-y:auto}*/

/*.modal-dialog{position:relative;margin:.5rem;pointer-events:none;min-width:500px;width:fit-content}*/

/*.modal.fade .modal-dialog{transition:-webkit-transform .3s ease-out;transition:transform .3s ease-out,-webkit-transform .3s ease-out;-webkit-transform:translate(0,-25%);transform:translate(0,-25%)}*/
/*@media screen and (prefers-reduced-motion:reduce){.modal.fade .modal-dialog{transition:none}}*/

/*.modal.show .modal-dialog{-webkit-transform:translate(0,0);transform:translate(0,0)}*/
/*.modal-dialog-centered{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:calc(100% - (.5rem * 2))}*/

/*.modal-content{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;pointer-events:auto;background-color:#fff;background-clip:padding-box;border:1px solid rgba(0,0,0,.2);border-radius:.3rem;outline:0;padding:40px}*/

/*.modal-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1040;background-color:#000}*/
/*.modal-backdrop.fade{opacity:0}*/
/*.modal-backdrop.show{opacity:.5}*/

/*.modal-header{display:-ms-flexbox;display:flex;-ms-flex-align:start;align-items:flex-start;-ms-flex-pack:justify;justify-content:space-between;padding:1rem;border-bottom:1px solid #e9ecef;border-top-left-radius:.3rem;border-top-right-radius:.3rem}*/
/*.modal-header .close{padding:1rem;margin:-1rem -1rem -1rem auto}*/

/*.modal-title{margin-bottom:0;line-height:1.5}*/

/*.modal-body{position:relative;-ms-flex:1 1 auto;flex:1 1 auto;padding:1rem}*/

/*.modal-footer{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end;padding:1rem;border-top:1px solid #e9ecef}*/
/*.modal-footer>:not(:first-child){margin-left:.25rem}.modal-footer>:not(:last-child){margin-right:.25rem}*/

/*@media (min-width:576px){.modal-dialog{margin:1.75rem auto;}.modal-dialog-centered{min-height:calc(100% - (1.75rem * 2))}}*/

/*@media (max-width: 768px){*/
/*    .modal-dialog{*/
/*        max-width:95%;*/
/*        min-width: 95%;*/
/*        margin:5px auto 5px auto;*/
/*    }*/
/*    .modal-content{*/
/*        padding:10px*/
/*    }*/
/*    .modal {*/
/*        min-width: 95%;*/
/*    }*/
/*}*/

.modal {
    font-family: "Oswald", sans-serif;
    font-weight: 100;
}

.modal .modal-title {
    font-weight: 300;
    font-size: 35px;
}

.modal {
    display: flex !important;
    vertical-align: middle; }
.modal .modal-dialog {
    margin: auto; }
.modal .modal-header {
    display: inline;
    display: initial;
    text-align: center;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 2em; }
.modal .modal-header .modal-title {
    display: inline-block;
    color: #000000;
    font-family: "Oswald", sans-serif; }
.modal .modal-body {
    padding: 0;
    font-family: "Oswald", sans-serif; }
.modal .modal-body .question-box.question-form {
    border: none;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 2em; }
.modal .modal-body .question-box.question-form .form-control {
    height: 3em; }
.modal .modal-body .question-box.question-form .btn.btn-default.btn-admin {
    font-family: "Oswald", sans-serif;
    background: #0000ff;
    color: #000000;
    width: 65%;
    height: 3em;
    border: none;
}

.react-confirm-alert > h1, .react-confirm-alert  {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    word-wrap: break-word;
}

#react-confirm-alert .react-confirm-alert > h1 {
    color: #000;
    margin-bottom: 10px;
}

#react-confirm-alert .react-confirm-alert h1 {
  font-size: 6vw;
}

#react-confirm-alert .react-confirm-alert {
    width:90%;
}

#react-confirm-alert .react-confirm-alert > h3 {
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-body {
  width: auto;
  text-align: center;
  max-width: 100%;
}

#react-confirm-alert .react-confirm-alert-button-group {
justify-content: center;
}

#react-confirm-alert .react-confirm-alert-button-group > button {
    background: #000;
    letter-spacing: 1.2px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-overlay {
    /*background: rgba(255, 255, 255, 0.3);*/
}

body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    -webkit-filter: url(#gaussian-blur);
            filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    /*background: rgba(255, 255, 255, 0.9);*/
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: "Oswald", sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#react-confirm-alert .react-confirm-alert {
    margin: 0 1em 0 1em; }

#react-confirm-alert .react-confirm-alert h1 {
    font-family: "Oswald", sans-serif;
    color: #000000;
    margin-bottom: 10px;
    text-shadow: none; }

#react-confirm-alert .react-confirm-alert-button-group {
    justify-content: center; }

#react-confirm-alert .react-confirm-alert-button-group button {
    font-size: 1.8em;
    font-weight: 800;
    height: 2em;
    width: 80%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #fff;
    background-color: #cc172c;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }

/*#react-confirm-alert .react-confirm-alert-overlay {*/
/*  background: rgba(255, 255, 255, 0.3); }*/

#react-confirm-alert .react-confirm-alert-overlay {
    z-index: 1051; }

#react-confirm-alert h3 {
    font-family: "Oswald", sans-serif;
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
    color: #000000;
    line-height: 1.3em; }

html,body {
    background-color: black;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Oswald", sans-serif !important;
    background-color: transparent;
}

#root {
    /* background: url("../images/background.png") no-repeat center center fixed; */
    background-size: cover;
}

.btn-logout {
    background-color: transparent;
    background-image: none;
    border-color: #0000ff;
    color: #0000ff;
    font-family: "Oswald", sans-serif;
    margin-top: 1em;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.headerImage{
    max-height: 200px;
    width: auto;
    max-width: 100%;
}

.headerBody{
    width: 100%;
    font-family: "Oswald", sans-serif;
    text-align: center;
    font-size: 20px;
}

.headerText{
    text-align: center;
    width: 100%;
    font-family: "Oswald", sans-serif;
    font-size: 45px;
}

.vertical_spacer{
    width: 100%;
    height:20px;
}

.itemPopUpTitle{
    font-family: "Oswald", sans-serif;
    font-size: 32px;
}

.popUpHeader {
    font-family: "Oswald", sans-serif;
    font-size: 16px;
    text-align: center;
}

.popUpInput {
    border: black 1px solid;
    width: 100%;
    font-size: 20px;
    padding:5px 0;
    font-family: "Oswald", sans-serif;
    margin: 15px 0;
    border-radius: 10px;
}

.popUpButton{
    text-align: center;
    font-size: 20px;
    padding: 0 40px;
    font-family: "Oswald", sans-serif;
    border-radius: 5px;
    min-width: 30px;
}

.fullscreen{
    position:absolute;
    left:0;
    top:0;
    bottom: 0;
    right:0;
    width:100%;
    height:100%;
    background-color: black;
    text-align: center;
}

.qrScanner{
    min-width:280px;
    background-color: black;
}

.aboveQrcodeText{
    color:white;
    font-family: "Oswald", sans-serif;
}

/* Flexbox version */
::-webkit-scrollbar {
    width: 0;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

.flex-container-home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-size: cover;
}

.flex-container-home .flex-content-container-home .intro-container-home, .flex-container-home .flex-content-container-home .thanks-container-home {
    margin: 25px auto auto auto;
    /* padding-bottom: 4em;  */
}

.flex-container-home .scratch-container-home {
    margin: 2em auto auto; }
.flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-family: "Oswald", sans-serif;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
    line-height: 1; }

.flex-container-home .button-wrapper {
    display: flex;
    justify-content: center; }

.flex-container-home .button-item-container {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 4em; }
.flex-container-home .button-item-container .btn-scratcher {
    border-radius: 0 !important;
    bottom: 0;
    font-size: 1.8em;
    font-weight: 800;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #000000;
    background-color: #0000ff;
    text-transform: uppercase; }

.flex-content-container-home {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
}

.intro-container-home {
    margin: 0 auto auto;
    text-align: center;
}

.hero-text-container {
    padding-bottom: 1em;
    padding-top: 1em;
    margin: auto; }
.hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
    zoom: 45%; }
.hero-text-container .thanks-main-title {
    color: #0000ff;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    padding: 0 1em 0 1em; }
.hero-text-container .thanks-main-title h1 {
    text-shadow: none;
    font-size: 9vmax;
    line-height: 1em; }
.hero-text-container .thanks-main-title .intro-button-wrapper {
    text-align: center; }
.hero-text-container .login-main-subtitle, .hero-text-container .thanks-main-subtitle {
    color: white;
    text-align: center;
    font-size: 6vmax;
    font-family: "Oswald", sans-serif;
    font-weight: 600; }
.hero-text-container .login-main-subtitle {
    padding: 0 1em 0 1em; }
.hero-text-container .thanks-main-subtitle {
    padding: 0 0.5em 0 0.5em; }

.question-box #create-email-form label {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 300;
}

.question-box {
    background-color: white;
}

.btn.btn-play {
    margin-top: 10px;
    color: #fff;
    background-color: #cc172c;
    width: 250px;
    font-size: 1.4em;
    font-family: "Oswald", sans-serif;
    height: 50px;
    font-weight: 700;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-rules {
    margin-top: 15px;
    color: #fff;
    background-color: #000;
    width: 120px;
    font-size: 15px;
    font-family: "Oswald", sans-serif;
    height: 40px;
    font-weight: 700;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-rules:hover {
    color: #fff;
}

@media (min-width: 600px) {
    .intro-container-home {
        margin: 5% auto auto;
    }
}

@media  (max-width: 600px) {
    .flex-container-home h2 {
        font-size: 1.5em;
    }
    .btn.btn-play {
        width: 150px
    }
}

